




















































































































































































































































import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { Program } from "@/types";
import { AuthData, UserProfile } from "@/store/auth/types";
import axios, { AxiosRequestHeaders } from "axios";

@Component<ApplyKoop>({
  metaInfo() {
    return {
      title: this.pageTitle
    };
  },
  components: {
    PageHeader,
    Breadcrumbs
  }
})
export default class ApplyKoop extends Vue {
  get breadcrumbs(): unknown[] {
    return [
      {
        text: "Ana Sayfa",
        exact: true,
        to: { name: "Home" }
      },
      {
        text: this.pageTitle
      }
    ];
  }

  get pageTitle(): string {
    return "Program Başvurusu";
  }

  get authData(): AuthData {
    return this.$store.getters["auth/data"];
  }

  get isUserLoggedIn(): boolean {
    return this.$store.getters["auth/isUserLoggedIn"];
  }

  user = {} as UserProfile;
  
  get nameRules(): unknown {
    return [(v: string) => !!v || "Lütfen adınızı yazınız"];
  }

  get corpNameRules(): unknown {
    return [(v: string) => !!v || "Lütfen kurum adınızı yazınız"];
  }

  get tcknRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen TC kimlik numaranızı yazınız",
      (v: string) => /^[1-9]{1}[0-9]{9}[02468]{1}$/.test(v) || "Geçersiz TC kimlik numarası"
    ];
  }

  get taxNumberRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen vergi numaranızı yazınız",
      (v: string) => /^[0-9]{10}$/.test(v) || "Geçersiz vergi numarası"
    ];
  }

  get emailRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen e-postanızı yazınız",
      (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
    ];
  }

  get phoneRules(): unknown {
    return [
      (v: string) => !!v || "Lütfen telefon numaranızı yazınız",
      (v: string) =>
        // Tr telefon 0532 111 11 11 ya da 05321111111
        /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/.test(v)
    ];
  }

  get addressRules(): unknown {
    return [(v: string) => !!v || "Lütfen açık adresinizi yazınız"];
  }

  get titleOfCooperativeRules(): unknown {
    return [(v: string) => !!v || "Lütfen kooperatifinizin unvanını yazınız"];
  }

  get roleInCooperativeRules(): unknown {
    return [(v: string) => !!v || "Lütfen kooperatifinizdeki görevinizi yazınız"];
  }

  get receiptRules(): unknown[] {
    return [
      (v: File) => !!v || "Lütfen banka dekontunu yükleyiniz",
      (v: File) => !v || v.size < 3145728 || "Dosya boyutu 3 MB'den küçük olmalıdır"
    ];
  }

  applicationId = "";
  submitDisabled = false;
  msgDialog = false;

  program = {} as Program;

  form = {
    billType: "individual" as "individual" | "corporate",
    name: `${this.authData.firstName} ${this.authData.lastName}`,
    corpName: "",
    tckn: "",
    taxNumber: "",
    email: this.authData.email,
    phone: "",
    address: "",
    confirm: false,
    titleOfCooperative: "",
    roleInCooperative: "",
    receipt: null as File | null,
    status: "not-approved" as "not-approved" | "approved",
    statusMessage: ""
  };

  receiptLoading = false;

  validate() {
    const form: any = this.$refs.form;
    return form.validate();
  }

  async submit() {
    // Formu kontrol et
    if (!this.validate()) return;

    // Onay al
    var confirm = await this.$confirm("Başvuruyu göndermek istediğinizden emin misiniz?", { title: "Başvuru Onayı"});
    if (!confirm) return;

    this.submitDisabled = true;

    this.$notify({
      text: "Başvurunuz kaydediliyor. Lütfen bekleyin..."
    });

    // Başvuruyu kaydet
    try {
      const formData = new FormData();

      formData.append("userId", this.user.id);
      if (this.program.id) formData.append("programId", this.program.id);
      formData.append("billType", this.form.billType);
      formData.append("name", this.form.name);
      formData.append("tckn", this.form.tckn);
      if (this.program.price) formData.append("amount", this.program.price.toString());
      formData.append("email", this.form.email);
      formData.append("phone", this.form.phone);
      formData.append("address", this.form.address);
      formData.append("status", this.form.status);
      formData.append("statusMessage", this.form.statusMessage);
      if (this.form.corpName) formData.append("corpName", this.form.corpName);
      if (this.form.taxNumber) formData.append("taxNumber", this.form.taxNumber);

      var addtionalInfo = null;
      
      if (this.program.urlCode == "kooperatifcilik") {
        addtionalInfo = {
          titleOfCooperative: this.form.titleOfCooperative,
          roleInCooperativeRules: this.form.roleInCooperative
        };
      }

      if (addtionalInfo) formData.append("addtionalInfo", JSON.stringify(addtionalInfo));

      if (this.form.receipt) formData.append("receipt", this.form.receipt);

      const authHeader = this.$store.getters["auth/requestAuthHeader"];
      const contentTypeHeader = { "Content-Type": "multipart/form-data" };

      // Call add program api
      var res = await axios.post("/api/program/create-order", formData, {
        headers: Object.assign(authHeader, contentTypeHeader)
      });

      // Get applicationId
      this.applicationId = res.data;

      // Dialog göster
      this.msgDialog = true;
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Program başvurusu onaylanamadı. Lütfen daha sonra tekrar deneyiniz.`
      });

      throw e;
    } finally {
      this.submitDisabled = false;
    }
  }

  async getProgram() {
    try {
      const programUrlCode = this.$route.params.programUrlCode;
      const authHeader = this.$store.getters["auth/requestAuthHeader"];

      // Get course
      var res = await axios.get(`/api/program/get-by-urlCode/${programUrlCode}`, {
        headers: authHeader
      });

      if (res.data) {
        this.program = res.data;
      }

      console.log(this.program);
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Program bilgileri alınamadı.`
      });

      throw e;
    }
  }

  closeDialog() {
    this.msgDialog = false;
    this.$router.push({ name: "MyOrders"});
  }

  async mounted() {
    this.user = await this.$store.dispatch("auth/getUser");
    this.form.name = `${this.user.firstName} ${this.user.lastName}`;
    this.form.tckn = this.user.tckn;
    this.form.email = this.user.email;
    this.form.phone = this.user.phone;
    if (this.user.address) this.form.address = this.user.address;

    this.getProgram();
  }
}
